.tracking-page {
  text-align: center;
  padding: 10px;
}

.company-logo {
  max-width: 200px; 
  height: auto; 
  margin-bottom: 5px;
}

.title {
  margin-bottom: 10px;
  font-size: 12px;
}

.heading {
  margin-bottom: 10px;
  font-size: 22px;
}

.order-info {
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .company-logo {
    max-width: 150px 
  }

  .title {
    font-size: 1.5rem; 
  }

    .heading {
    font-size: 1.5rem; 
  }
}

.no-underline {
  text-decoration: none;
}

.order-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border: 2px solid #00A699; 
  padding: 25px; 
}

.order-info-column {
 text-align: left;
  flex: 1;
  padding: 0 35px;
}

.info-bold {
  font-weight: bold;
  color: black;
}

@media (max-width: 600px) {
  .order-info {
    flex-direction: column;
  }
}

.my-icon {
  color: #00A699; /* Replace 'blue' with your desired color */
}

.error-message {
  color: red;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid red;
  border-radius: 5px;
}