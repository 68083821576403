.delivery-experience {
  text-align: center;
  margin: 20px 0;
}

.experience-question {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.experience-options {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.experience-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  font-size: 3em; 
  cursor: pointer;
  transition: transform 0.3s;
}

.experience-button:hover,
.experience-button.selected {
  transform: scale(1.2);
}

.experience-button.disabled {
  cursor: not-allowed;
  opacity: 0.8; 
  transform: scale(1); 
}

.experience-label {
  font-size: 0.2em;
  margin-top: 0.1em;
  color: #7b0808;
}

.feedback-message {
  margin-top: 20px;
  color: green;
  font-weight: bold;
}

@media (max-width: 600px) {
  .experience-options {
    justify-content: space-around;
  }
  .experience-button {
    font-size: 2em; 
  }
  .experience-label {
    font-size: 0.3em; 
  }
}
